import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["row", "dialog", "deleteForm"]

  showDialog(event) {
    event.preventDefault()
    this.dialogTarget.showModal()
    this.deleteFormTarget.action = `/admin/joke/${event.target.dataset.jokeId}/process`
  }

  closeDialog() {
    this.dialogTarget.close()
  }

  deleteJoke(event) {
    event.preventDefault()
    this.deleteFormTarget.requestSubmit()
	this.closeDialog()
  }
}