import "@picocss/pico/css/pico.green.min.css";
import "./app.css";

import * as Turbo from "@hotwired/turbo";
import { Application } from "@hotwired/stimulus";

import CaptchaController from "./controllers/captcha_controller";
import JokeListController from "./controllers/joke_list_controller";
import RandomJokeController from "./controllers/random_joke_controller";

window.Stimulus = Application.start();
Stimulus.register("captcha", CaptchaController);
Stimulus.register("joke-list", JokeListController);
Stimulus.register("random-joke", RandomJokeController);