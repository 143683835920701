import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["joke", "notification"]

  copyToClipboard() {
	let joke = this.jokeTarget.innerText

	navigator.clipboard.writeText(joke)

	if (this.notificationTarget.hasChildNodes()) {
		this.notificationTarget.firstChild.remove();
	}

	const toast = document.createElement("div");
	const article = document.createElement("article");
	article.classList.add("pico-background-green-500");


	const content = document.createElement("div");
	content.innerText = "Copied to clipboard!";

	toast.appendChild(article);
	article.appendChild(content);

	this.notificationTarget.appendChild(toast);
	setTimeout(() => {
	  toast.remove();
	}, 3000);
  }
}